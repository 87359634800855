import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import About from './pages/about';
import ContactUs from './pages/contact-us';
import NavBar from './menu';
import Login from './pages/login';
import AllCurrencyConverterPrivaryPolicy from './pages/all-currency-converter-privacy-policy';

function App() {
  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/login' element={<Login />} />
        <Route path='/all-currency-converter-privacy-policy' element={<AllCurrencyConverterPrivaryPolicy />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
