import React from "react";
import { Link } from "react-router-dom";

const imageStyle = { width: "100%", height: "auto", borderRadius: 20 };

const Home = () => {
  const cardList = [{}, {}, {}, {}, {}, {}, {}, {}];

  return (
    <div>
      <div className="container mt-5">
        <div className="row gy-5">
          <div className="col-sm-6 col-md-6 col-lg-6 d-flex align-items-center order-2">
            <div>
              <h2 className="text-center">
                Hello,{" "}
                <strong className="text-primary text-center">
                  Welcome Back!
                </strong>
              </h2>
              <p className="text-center">
                I am a web developer I am a web developer I am a web developer I
                am a web developer I am a web developer I am a web developer{" "}
              </p>
              <div className="d-flex justify-content-center">
                <button className="btn btn-primary">Getting Started</button>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6 order-sm-2 order-md-2 order-lg-2 order-1">
            <div>
              <img
                src="https://img.freepik.com/premium-photo/astronaut-outer-open-space-planet-earth-stars-provide-background-erforming-space-planet-earth-sunrise-sunset-our-home-iss-elements-this-image-furnished-by-nasa_150455-16829.jpg?w=2000"
                alt="..."
                style={imageStyle}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-5 mb-5">
        <div className="row gy-5">
          <div className="col-sm-6 col-md-6 col-lg-6">
            <div>
              <img
                src="https://cdn.searchenginejournal.com/wp-content/uploads/2022/06/image-search-1600-x-840-px-62c6dc4ff1eee-sej-1280x720.png"
                alt="..."
                style={imageStyle}
              />
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-6 d-flex align-items-center">
            <div>
              <h2 className="text-center">
                Hello,{" "}
                <strong className="text-primary text-center">
                  Welcome Back!
                </strong>
              </h2>
              <p className="text-center">
                I am a web developer I am a web developer I am a web developer I
                am a web developer I am a web developer I am a web developer{" "}
              </p>
              <div className="d-flex justify-content-center">
                <button className="btn btn-primary">Getting Started</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr></hr>
      <h1 className="text-center">Platforms</h1>
      <div class="container mt-5 mb-5">
        <div class="row gy-5">
          {cardList.map((item, index) => {
            return (
              <div key={index} class="col-sm-6 col-md-4 col-lg-3">
                <div className="card">
                  <img
                    src="https://thumbs.dreamstime.com/b/environment-earth-day-hands-trees-growing-seedlings-bokeh-green-background-female-hand-holding-tree-nature-field-gra-130247647.jpg"
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title">Card title</h5>
                    <p className="card-text">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                    <Link to="/contact-us" className="btn btn-primary">
                      Go somewhere
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Home;
