import React from "react";

const AllCurrencyConverterPrivaryPolicy = () => {
  return (
    <div className="container">
      <h3 className="text-center">All Currency Converter Privacy Policy</h3>
      <p>
        This privacy policy sets out how All Currency Converter uses and
        protects any information that you give when you use this app.
      </p>
      <p>
        All Currency Converter may change this policy from time to time by
        updating this page. You should check this page from time to time to
        ensure that you are happy with any changes. This policy is effective
        from May 25, 2018
      </p>
      <h5>We may require certain uses permission to use this app </h5>
      <pre>
        <div>
          <div>
            &lt;uses-permission
            android:name="android.permission.ACCESS_NETWORK_STATE" /&gt;
          </div>
        </div>
        <div>
          <div>
            &lt;uses-permission android:name="android.permission.INTERNET" /&gt;
          </div>
        </div>
        App required ACCESS_NETWORK_STATE & INTERNET Permission to use this app.
      </pre>
      <h5>Why do app need these permissions</h5>
      <p>
        App needs to connect with Internet to load the Live Currency Exchange
        rates from server to show Current rates in the app which required these
        permissions.
      </p>
      <h5>Links to other Apps</h5>
      <p>
        Our App may contain links to other Apps of interest. However, once you
        have used these links to leave our site, you should note that we do not
        have any control over that other App. Therefore, we cannot be
        responsible for the protection and privacy of any information which you
        provide whilst visiting such sites and such sites are not governed by
        this privacy statement. You should exercise caution and look at the
        privacy statement applicable to the App in question.
      </p>
      <h5>Controlling your personal information</h5>
      <p>
        We don’t collect any user’s personal information. So feel free while
        using All Currency Converter app. To give any feedback and suggestions
        feel free to contact us Email: robincestudio@gmail.com
      </p>
    </div>
  );
};

export default AllCurrencyConverterPrivaryPolicy;
